<!-- eslint-disable tailwindcss/no-custom-classname -->
<ng-container>
  <div class="tw-absolute tw--z-10 tw--mt-48 tw-h-[28rem] tw-w-full tw-bg-background-alt2"></div>
  <div class="tw-min-w-4xl tw-mx-auto tw-flex tw-max-w-screen-xl tw-gap-12 tw-px-4">
    <div class="tw-w-1/2">
      <img
        alt="Seidor Kryptos"
        style="height: 50px; width: 335px"
        class="tw-mt-6"
        src="../../../../images/register-layout/logo-horizontal-white.svg"
      />
      <div class="tw-pt-12">
        <app-secrets-manager-content></app-secrets-manager-content>
      </div>
    </div>
    <div class="tw-w-1/2">
      <div class="tw-pt-44">
        <div class="tw-rounded tw-border tw-border-solid tw-border-secondary-300 tw-bg-background">
          <div
            *ngIf="!freeOrganization"
            class="tw-flex tw-h-auto tw-w-full tw-gap-5 tw-rounded-t tw-bg-secondary-100"
          >
            <h2 class="tw-pb-4 tw-pl-4 tw-pt-5 tw-text-base tw-font-bold tw-uppercase">
              {{
                "startYour7DayFreeTrialOfBitwardenSecretsManagerFor"
                  | i18n: organizationTypeQueryParameter
              }}
            </h2>
            <environment-selector
              class="tw-mr-4 tw-mt-6 tw-flex-shrink-0 tw-text-end"
            ></environment-selector>
          </div>
          <app-secrets-manager-trial-free-stepper
            *ngIf="freeOrganization"
          ></app-secrets-manager-trial-free-stepper>
          <app-secrets-manager-trial-paid-stepper
            *ngIf="!freeOrganization"
            [organizationTypeQueryParameter]="organizationTypeQueryParameter"
          ></app-secrets-manager-trial-paid-stepper>
        </div>
      </div>
    </div>
  </div>
</ng-container>
