<div class="tw-mt-5 tw-flex tw-justify-center">
  <div>
    <img src="../../images/logo-dark@2x.png" class="logo" alt="Seidor Kryptos" />
    <p class="tw-text-center tw-my-4">
      <i
        class="bwi bwi-spinner bwi-spin bwi-2x tw-text-muted"
        title="{{ 'loading' | i18n }}"
        aria-hidden="true"
      ></i>
      <span class="tw-sr-only">{{ "loading" | i18n }}</span>
    </p>
  </div>
</div>
